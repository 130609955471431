import React from "react";
import PitchButton from "../components/buttons/PitchButton";
import Main from "../static-components/html/Main";

export default function LegalPage(){
  return (
      <Main title="Page not found">
        <div className="NotFound text-center pt-5">
          <h3>Sorry, page not found!</h3>
        </div>
        <div className="text-center pt-5">
          <PitchButton href="/">Go home</PitchButton>
        </div>
      </Main>
  );
}